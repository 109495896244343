/* Settings */
@import '../_base/_normalize.css';
@import '../_base/_sinclair.variables.css';
@import '../_base/_sinclair.fonts.css';
@import '../_base/_grid.css';
@import '../_base/_base.css';
@import '../_base/_sinclair.typography.css';

/* Atoms */
@import-glob '../01-atoms/**/*.css';

/* Molecules */
@import-glob '../02-molecules/**/*.css';

/* Organisms */
@import-glob '../03-organisms/**/*.css';

/* Pages */
@import-glob '../04-pages/**/*.css';

/* Themes */
@import '../_base/_sinclair.themes.css';

@import '../_base/_sinclair.navigation.css';
/* Overwrites of Perfectha for Corporate theme */

:root {
  --header-height: 110px;
  --header-height-desktop: 156px;
}

// For elements that are not visually shown but still present in DOM - for screen readers and crawlers
.sr-only {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide-scrollbar {
  overflow: hidden;
}

.overflow-container {
  --double-spacing: calc(var(--container-spacing--mobile) * 2);

  width: calc(100% + var(--double-spacing));

  margin-left: calc(var(--container-spacing--mobile) * -1);
  margin-right: calc(var(--container-spacing--mobile) * -1);
  padding-left: var(--container-spacing--mobile);
  padding-right: var(--container-spacing--mobile);

  @media (--viewport-md-min) {
    --double-spacing: calc(var(--container-spacing) * 2);
    margin-left: calc(var(--container-spacing) * -1);
    margin-right: calc(var(--container-spacing) * -1);
    padding-left: var(--container-spacing);
    padding-right: var(--container-spacing);
  }
}

/* Overwrites of Perfectha for Corporate theme */
a.button,
.button {
  &:hover {
    background-color: var(--color-midnight-blue);
    color: white;
  }

  &:before {
    content: none;
  }

  min-width: 246px;
  min-height: 57px;
  padding-left: 16px;
  padding-right: 16px;

  text-transform: uppercase;
  font-family: var(--base-font-family);

  background: transparent;
  border: 1px solid var(--color-midnight-blue);
  border-radius: 4px;
  font-size: 0.875rem;

  color: var(--color-midnight-blue);
  transition: 0.1s;
  letter-spacing: 2px;

  @media (--viewport-md-min) {
    min-height: 65px;
    min-width: 281px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

a.button--reverse,
.button--reverse {
  color: var(--color-white);
  border: 1px solid white;

  &:hover {
    background-color: var(--color-white);
    color: var(--color-midnight-blue);
  }
}

.label--line {
  color: currentColor;

  &:before {
    margin-right: 20px;
    content: '';
    width: 71px;
    height: 1px;
    display: inline-block;
    border-bottom: solid 1px currentColor;
    position: relative;
    top: -5px;
  }

  @media (--viewport-md-min) {
    &:before {
      top: -6px;
      width: 90px;
    }
  }

  &--ghost {
    background: transparent;
    border-radius: var(--input-border-radius);
    color: var(--color-midnight-blue);
    border: var(--input-border-width) solid var(--color-midnight-blue);
    font-weight: 600;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 20px 64px;

    &:hover {
      background: var(--color-midnight-blue);
      color: var(--color-white);
    }
  }
}

a.button--chip,
.button--chip {
  min-width: auto;
  min-height: auto;
  font-size: 0.75rem;
  text-transform: capitalize;
  padding: 10px;
  background-color: var(--color-background-01);
  margin-left: 10px;
}

.background-image-container {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
}

.header-spacer {
  display: block;
}

/* Main navigation overwrite */
.main-navigation {
  @media (--navigation-breakpoint-mobile) {
    background: var(--color-background-01);

    &__close {
      color: var(--color-text);
    }

    &-service-list {
      padding-top: 20px;

      &-item {
        opacity: 1;

        &__link {
          font-size: 0.75rem; /** 12px **/
        }
      }
    }

    &-list {
      &-item {
        color: var(--color-white);

        &--active {
          color: var(--color-primary);
        }

        &-sublist-item {
          color: var(--color-white);
        }
      }
    }
  }

  @media (--navigation-breakpoint-desktop) {
    &-list {
      &-item {
        padding: 8px 0 22px;
        &::after {
          background: var(--color-white);
          margin-top: -12px;
        }

        &-sublist {
          box-shadow: 0 0 10px 10px color(var(--color-white) a(15%));

          &-item {
            color: var(--color-text);

            &:hover {
              color: var(--color-link);
            }

            &__link {
              border-bottom-color: color(var(--color-primary) a(25%));
            }
          }
        }
      }
    }
  }
}

/* Corporate 2022 Footer */
.footer {
  &-logo-col .logo {
    margin: 48px auto 0;

    @media (--viewport-md-min) {
      margin: 64px auto 0;
    }

    &__image {
      height: auto;
      width: 160px;

      @media (--viewport-md-min) {
        width: 190px;
      }
    }
  }

  .navigation-list {
    &-item {
      margin: 0;
      color: var(--color-footer-text);
      font-size: 0.75rem; /* 12px */

      @media (--viewport-md-min) {
        font-size: 0.8125rem; /* 13px */
        letter-spacing: 0.1em;
      }

      & a {
        color: inherit;
        font-weight: 300;
        font-size: inherit;

        &:hover,
        &:focus {
          color: var(--color-blue);
        }
      }

      /* add divider behind each item */
      &:after {
        content: '|';
        font-weight: 300;
        margin-left: 8px;
        margin-right: 4px;
      }

      /* hide last "|" divider */
      &:last-of-type:after {
        content: none;
      }
    }
  }

  &-other-brands {
    @media (--viewport-md-min) {
      padding-bottom: 32px;
      padding-top: 40px;
      margin-top: 32px;
    }

    &__label {
      font-size: 0.875rem; /* 14px */
      font-weight: 300;

      @media (--viewport-md-min) {
        font-size: 1rem; /* 16px */
        letter-spacing: 0.1em;
      }
    }

    & .logo {
      @media (--viewport-xs-max) {
        display: block;
        margin: 24px auto;
      }

      &__image {
        height: auto;

        @media (--viewport-md-min) {
          height: auto;
        }
      }
    }
  }

  .copyright {
    font-size: 0.75rem; /* 12px */
    font-weight: 300;
    text-align: center;
    width: 100%;
    justify-content: center;

    @media (--viewport-md-min) {
      font-size: 0.8125rem; /* 14px */
      letter-spacing: 0.07em;
    }
  }

  .social-profiles {
    margin-top: 0px;
    margin-bottom: 16px;

    &__link {
      .icon {
        /** See https://novicell.atlassian.net/wiki/spaces/SIP/pages/3458465848/Recolouring+SVGs+with+filter for this technique **/
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(72deg)
          brightness(107%) contrast(101%);
      }
    }

    @media (--viewport-md-min) {
      margin-top: 12px;
      margin-bottom: 8px;
    }
  }
}

/* Navigation overwrite */
.navigation {
  &--service {
    @media (--viewport-sm-max) {
      display: none;
    }

    @media (--viewport-md-min) {
      &:before {
        background: transparent;
      }
    }
  }

  &-list {
    &-item {
      background: transparent;

      &:last-child {
        padding-right: 0;
        &.navigation-list-item__region-selector-toggle {
          padding-right: 22px;
        }
      }

      &--active {
        &:before {
          top: 0;
          background: transparent;
        }
      }
    }
  }
}

/* Mobile menu overwrite */
.mobile-menu-toggle {
  &:before,
  &:after,
  &__label:after {
    background-color: var(--color-background-02);
    border-radius: 5px;
  }

  &__label {
    position: relative;
    font-size: 0;
    color: transparent;
    width: 35px;
    height: 17px;

    &:after {
      content: '';
      display: block;
      background-color: var(--color-eminence);
      width: 100%;
      height: 3px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--color-background-02);
    }
  }
}

/* Site selector overwrite */
.site-selector {
  &-toggle {
    &__label {
      color: var(--color-black);
    }

    &__expand-arrow  {
      color: var(--color-black);
    }
  }
}

.sinclair-homepage {
  .header-spacer {
    display: none;
  }

  .header--regular {
    /* Main navigation overwrite */
    .main-navigation {
      @media (--viewport-md-min) {
        &-list {
          &-item {
            &__link {
              color: var(--color-white);

              &:hover {
                color: var(--color-white);
              }
            }
          }
        }
      }
    }
  }

  /* Navigation overwrite */
  .navigation {
    &-list {
      &-item {
        &--active {
          & > a {
            color: var(--color-white);
          }
        }
      }
    }
  }

  /* Site selector overwrite */
  .site-selector {
    &-toggle {
      &__label {
        color: var(--color-white);
      }

      &__expand-arrow  {
        color: var(--color-white);
      }
    }
  }
}

.logo {
  &__image {
    height: 18px;
  }
}

.social-media-banner {
  &-content {
    &-profiles {
      &__link {
        color: var(--color-white);

        &:focus,
        &:hover {
          color: var(--color-secondary);
        }
      }
    }
  }
}

.hero {
  padding-top: 24px;
  padding-bottom: 24px;

  @media (--viewport-md-min) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &--simple {
    margin-top: 0;

    @media (--viewport-md-min) {
      margin-top: 0;
    }
  }

  &-head {
    &-title {
      @media (--viewport-md-min) {
        font-size: 3rem; /* 48px */
      }

      &:after {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;

        @media (--viewport-md-min) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

.content-slider {
  /* remove padding-bottom from content-slider cause it interferes with the spacing below
  the event component */
  padding-bottom: 0;
}

.location-finder-page {
  .header--sinclair {
    margin: 0;
  }
  .location-finder {
    margin-top: 0;
    height: calc(100vh - 83px);
    @media (--viewport-md-min) {
      height: calc(100vh - 62px);
    }
    @media (--viewport-lg-min) {
      height: calc(100vh - 114px);
    }
  }
}

.input {
  border-color: var(--input-border-color);

  &__wrapper label {
    display: inline-block;
    margin-bottom: 12px;
  }
}

.form-group__label {
  padding-left: 0;
  margin-bottom: 10px;
}

.footer {
  &-logo-col .logo {
    @media (--viewport-md-min) {
    }
  }

  & .navigation-list {
    &-item {
      margin: 0;
      font-weight: 300;
      font-family: var(--base-font-family);

      & a {
        font-size: 0.75rem; /* 12px */
      }

      /* add divider behind each item */
      &:after {
        content: '|';
        margin-left: 8px;
        margin-right: 4px;
      }

      /* hide last "|" divider */
      &:last-of-type:after {
        content: none;
      }
    }
  }

  &-other-brands {
    width: 100%;

    &__items {
      .logo__image {
        margin: 0 auto;
      }

      & img {
        @media (--viewport-md-min) {
          margin-bottom: 0;
        }
      }

      @media (--viewport-md-min) {
        .logo {
          margin-right: 50px;
        }
      }
    }

    &__label {
      font-size: 0.875rem; /* 14px */
      font-weight: 400;
    }

    & .logo {
      &__image {
        height: auto;
        width: 106px;

        @media (--viewport-md-min) {
          height: auto;
        }
      }
    }
  }

  & .copyright {
    font-size: 0.75rem; /* 12px */
    text-align: center;
  }
}
.blog-post-hero {
  margin-top: 24px;

  @media (--viewport-md-min) {
    margin-top: 80px;
  }
}

.visualiser-wrapper {
  @media (--viewport-md-min) {
    margin-top: 0;
  }
}
.interactive-patient-preview {
  &-overlay {
    &__link:hover {
      color: var(--color-white);
    }
  }
}

ul,
ol {
  font-family: var(--base-font-family);
}

.rte,
.appetiser {
  ul,
  ol {
    font-family: var(--base-font-family);

    li {
      @media (--viewport-md-min) {
        font-size: 1rem; /** 16px **/
      }

      margin-bottom: 16px;
    }
  }

  ul {
    li {
      position: relative;
      font-size: 1rem;
      padding-left: 1.5rem;
      line-height: 1.25;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        left: 0;
        background: var(--color-list-dot);
        border-radius: 100%;
        width: 5px;
        height: 5px;
      }
    }
  }

  ol {
    counter-reset: li;

    li {
      counter-increment: li;
      line-height: 1.25;
      position: relative;
      padding-left: 24px;

      &:before {
        /* content: counter(li) '.'; */
        display: block;
        position: absolute;
        top: -2px;
        left: 0;
        font-size: 1.125rem; /* 18px */
        font-weight: bold;
        color: var(--color-list-dot);
      }
    }
  }
}

.featured-event {
  margin-bottom: 0;

  &-inner {
    &-image {
      margin-bottom: 0;
      height: 333px;
      display: block;
    }

    &-content {
      position: relative;
      background-color: #d7c6e6;
      margin-left: 0;
      padding-bottom: 58px;

      --date-box-size: 154px;
      --date-box-size-negative: calc(var(--date-box-size) * -1);

      &__title {
        padding-top: 110px;
        color: var(--color-midnight-blue);
        font-weight: 500;
        font-size: 1.25rem; /** 20px **/
        line-height: 1.4;
      }

      &__description {
        display: block;
        overflow: hidden;
        font-family: var(--base-font-family);
        color: var(--color-midnight-blue);
        font-size: 0.875rem; /** 14px **/
        line-height: 1.35;
        font-weight: 400;
        position: relative;
        height: 8em;

        &:after {
          /** Fade out text **/
          content: '';
          text-align: right;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 3em;
          background: linear-gradient(
            to bottom,
            rgba(215, 198, 230, 0),
            rgba(215, 198, 230, 1) 50%
          );
        }
      }

      &__date {
        width: var(--date-box-size);
        height: var(--date-box-size);
        background-color: white;
        text-align: center;
        line-height: 1;
        position: absolute;
        top: calc(var(--date-box-size-negative) / 2);
      }

      &__day {
        padding-top: 15px;
        font-weight: 700;
        font-family: var(--base-font-family);
        color: var(--color-midnight-blue);
        font-size: 95px;
      }

      &__month {
        font-weight: 500;
        font-size: 14px;
        color: var(--color-highlight);
      }
    }
  }

  @media (--viewport-md-min) {
    max-width: 1500px;
    height: 609px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    margin-bottom: 55px;

    &-inner {
      position: absolute;
      height: 400px;
      width: 697px;

      &-image {
        position: absolute;
        left: 10px;
        max-width: 919px;
        height: 609px;
        height: auto;
      }

      &-content {
        display: block;
        position: absolute;
        right: 10px;
        bottom: 0;
        width: 700px;
        height: 400px;
        padding-left: 105px;
        padding-right: 105px;

        --date-box-size: 154px;

        &__title {
          padding-top: 29px;
          padding-bottom: 0;
          font-weight: 500;
        }

        &__description {
          height: 7em;
          margin-bottom: 21px;
        }

        &__date {
          padding-bottom: 0;
          top: calc(var(--date-box-size-negative) / 1.2);
        }

        &__day {
        }

        &__month {
        }
      }
    }
  }
}

.event-card {
  width: 100%;
  &-content {
    color: var(--color-midnight-blue);
    background: none;
    position: relative;

    &-bg-media {
      position: relative;

      &__image {
        object-fit: cover;
        width: 100%;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--color-black);

      &-info {
        margin-bottom: 15px;
        text-align: right;
      }

      &-date {
        white-space: nowrap;
        position: relative;

        &__day {
          display: inline-block;
          font-family: var(--base-font-family);
          font-size: 119px;
          line-height: 1;
          font-weight: 700;
        }

        &__month {
          display: inline-block;
          color: var(--color-highlight);
          font-size: 18px;
          position: relative;
          top: -70px;
        }
      }
    }

    &-footer {
      position: relative;
      padding: 0;
      margin-bottom: 60px;

      &__title {
        font-size: 1.25rem; /** 20px **/
        color: inherit;
        margin-top: 32px;
        font-weight: 500;
      }

      &__cta {
        display: inline-block;
        font-family: var(--base-font-family);
        color: var(--color-highlight);
        font-weight: 600;
        font-size: 0.875rem; /** 14px **/
        text-transform: uppercase;
        letter-spacing: 2px;
        padding-bottom: 5px;
        margin-top: 20px;

        .icon {
          position: relative;
          top: 6px;
          left: 5px;
        }

        &:hover {
          border-bottom: 1px solid var(--color-highlight);
        }
      }
    }
  }
}

.event-listing--filter-grid {
  .event-listing-content {
    justify-content: center;
  }

  .event-listing-content-item {
    width: 100%;
    max-width: 600px;

    .event-card-content {
      &-bg-media__image {
        height: 327px;
      }
    }
  }
}

@media (--viewport-md-min) {
  .event-listing--filter-grid {
    .event-card-content-footer {
      padding-left: 0;
    }

    .event-card-content-bg-media img {
      max-height: 446px;
    }

    .event-listing-content {
      justify-content: space-between;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .event-listing-content-item {
      max-width: 627px;
    }
  }
}

.filters-panel {
  background: none;

  &-active-list {
    font-family: var(--base-font-family);

    &-item {
      color: var(--color-midnight-blue);
    }
  }

  &-section {
    &:first-child {
      padding-top: 27px;
    }
    padding-bottom: 30px;
  }

  &-heading {
    margin-bottom: 24px;
    &__title {
      font-size: 1.5rem;
      font-family: var(--heading-font-family);
    }
  }

  &-filter-list {
    &-item {
      &:not(:last-child) {
        padding-bottom: 12px;
      }

      &-checkbox {
        font-family: var(--base-font-family);
        &__input {
          background-color: transparent;
        }

        &__indicator {
          width: 24px;
          height: 24px;
          background: none;
          border-color: var(--color-midnight-blue);
          border-radius: 4px;

          svg {
            fill: var(--color-midnight-blue);
          }
        }
      }
    }
  }
}

.sidebar-trigger {
  background: none;
  padding-bottom: 40px;

  .button {
    display: block;
    border-radius: 0;
    text-align: left;
    text-transform: none;
    font-size: 1.125rem; /** 18px **/

    .icon {
      position: absolute;
      right: 24px;
      width: 18px;
      min-width: 18px;
      min-height: 18px;
    }
  }
}

.signup-form-overlay {
  background-color: var(--color-background-01);

  &-inner-image {
    width: 100%;
  }

  &-inner-content {
    @media (--viewport-md-min) {
      max-width: 1200px;
      min-width: 571px;
    }

    &-form {
      &__accept-terms {
        justify-content: flex-start;
        font-size: 0.8rem;

        .checkbox__tick {
          &:before {
            border: 1px solid var(--color-midnight-blue);
            background-color: var(--color-white);
            width: 26px;
            height: 26px;
          }

          margin-right: 20px;
        }

        .form-group__message {
          display: block;
          width: 100%;
        }
      }

      .cta {
        align-self: center;
        text-align: center;

        .button {
          min-width: 281px;
        }
      }
    }

    &-title {
      text-align: center;
      text-transform: none;
    }

    .input__wrapper {
      label {
        display: inline-block;
        font-size: 0.875rem; /** 14px **/
        margin-bottom: 10px;
      }

      .input {
        border: 1px solid var(--color-midnight-blue);
      }
    }
  }

  @media (--viewport-md-min) {
    &-inner-content {
      &-title {
        margin-top: 0px;
        text-align: left;
      }

      &-form {
        .cta {
          align-self: flex-start;
          text-align: left;

          .button {
            min-width: 281px;
          }
        }

        &-form {
          .form {
            align-items: flex-start;
          }
        }
      }
    }
  }
}

.b2b-signup-form-overlay {
  background-color: var(--color-background-01);

  &-inner-image {
    width: 100%;
  }

  &-inner-content {
    @media (--viewport-md-min) {
      max-width: 1200px;
      min-width: 571px;
    }

    &-form {
      .checkbox__tick {
        &:before {
          border: 1px solid var(--color-midnight-blue);
          background-color: var(--color-white);
          width: 26px;
          height: 26px;
        }

        margin-right: 20px;
      }
      &__accept-terms {
        justify-content: flex-start;
        font-size: 0.8rem;

        .form-group__message {
          display: block;
          width: 100%;
        }
      }

      .cta {
        align-self: center;
        text-align: center;

        .button {
          min-width: 281px;
        }
      }
    }

    &-title {
      text-align: center;
      text-transform: none;
    }

    .input__wrapper,
    .select__wrapper,
    .textarea__wrapper {
      label {
        display: inline-block;
        font-size: 0.875rem; /** 14px **/
        margin-bottom: 10px;
      }

      input,
      select,
      textarea {
        border: 1px solid var(--color-midnight-blue);
      }
    }
  }

  @media (--viewport-md-min) {
    &-inner-content {
      &-title {
        margin-top: 0px;
        text-align: left;
      }

      &-form {
        .cta {
          align-self: flex-start;
          text-align: left;

          .button {
            min-width: 281px;
          }
        }

        &-form {
          .form {
            align-items: flex-start;
          }
        }
      }
    }
  }
}

.rte-style-isi {
  a {
    text-decoration: underline;
  }
}

.quote-slider {
  .quote {
    min-height: 400px;
  }
}
.quote {
  padding-top: 0px;
  &-content {
    padding-top: 30px;
    padding-bottom: 30px;

    &__text {
      font-size: 1.5rem; /** 24px **/
      text-align: left;
      font-weight: 400;
    }

    &__author {
      margin-top: 5px;
    }

    &__seperator {
      margin-top: 50px;
      display: inline-block;
      width: 23px;
      border-bottom: 2px solid var(--color-midnight-blue);
    }
  }

  @media (--viewport-md-min) {
    &-content {
      &__text {
        text-align: center;
        font-size: 2.25rem; /** 36px; **/
        letter-spacing: 2px;
      }

      &__author {
        margin-top: 50px;
        font-weight: 500;
      }
      &__seperator {
        display: none;
      }
    }
  }
}

.image-darken {
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00000061;
  }
}

.cta-banner {
  position: relative;

  &-background {
    &--with-gradient {
      @extend .image-darken;
    }
  }
  &-content {
    padding-top: 250px;
    padding-bottom: 150px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;

    &__logo {
      max-width: 215px;
      width: 100%;
      display: block;
      margin-top: 22px;
      margin-bottom: 35px;
    }

    &__title {
      color: var(--color-white);
      display: block;
      width: 100%;
      font-weight: 500;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 80px;
      }
    }

    &__label {
      color: var(--color-white);
    }

    &__subtitle {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 34px;
      max-width: 341px;
      color: var(--color-white);
      font-family: var(--base-font-family);
      line-height: 1.8;
    }

    &__cta {
      margin-top: 25px;
      margin-bottom: 50px;
    }
  }

  &--dark {
    .cta-banner-content {
      &__title {
        color: var(--color-midnight-blue);
      }

      &__label {
        color: var(--color-midnight-blue);
      }

      &__subtitle {
        color: var(--color-midnight-blue);
      }
    }
  }

  @media (--viewport-md-min) {
    &-content {
      &__subtitle {
        margin-top: 20px;
        max-width: 500px;
      }
    }
    &-background--with-gradient {
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, rgba(42, 6, 63, 0.1), rgba(0, 0, 0, 1));
      }
    }
  }
}

.meet-the-team-list {
  padding-top: 80px;
  margin-bottom: 40px;

  &-content {
    &-item {
      width: 100%;

      @media (--viewport-ms-min) {
        width: 100%;
      }

      @media (--viewport-lg-min) {
        width: 100%;
      }

      .meet-the-team-card {
        &-content {
          margin: 0 auto;
        }
      }
    }

    margin: 0px;
    display: grid;

    grid-gap: 20px;

    @media (--viewport-sm-min) {
      & {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media (--viewport-lg-min) {
      & {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    @media (--viewport-xl-min) {
      & {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}

.meet-the-team-card {
  &-content {
    box-shadow: none;
    background: none;
    padding: 0;
    max-width: 411px;

    &__title {
      color: var(--color-midnight-blue);
      text-align: left;
      width: 100%;
      font-size: 1.5rem; /** 24px **/
      margin-bottom: 20px !important;
    }

    &__description {
      text-align: left;
      width: 100%;
      font-size: 1rem;
      color: var(--color-midnight-blue);
    }

    &__image {
      padding: 0;
      border: none;
      width: 100%;
      height: 100%;
      border-radius: 0;
      height: 411px;
      object-fit: cover;
    }

    &__link {
      text-align: left;
      width: 100%;
      display: block;
      text-transform: unset;
      color: var(--color-highlight);
      font-size: 0.875rem; /** 14px **/
      letter-spacing: 2px;
      font-weight: 500;

      .icon {
        width: 25px;
        height: 25px;
        vertical-align: middle;
        /** See https://novicell.atlassian.net/wiki/spaces/SIP/pages/3458465848/Recolouring+SVGs+with+filter for this technique **/
        filter: brightness(0) saturate(100%) invert(41%) sepia(37%) saturate(3227%)
          hue-rotate(329deg) brightness(109%) contrast(100%);
        margin-right: 10px;
      }

      &:hover {
        color: red;
      }
    }
  }
}

.faq-cards {
  &:before {
    background: transparent;
  }
}

.countdown-content-timer {
  background-color: var(--color-text);
  width: 40px;
  height: 40px;
  @media (--viewport-ms-min) {
    width: 50px;
    height: 50px;
  }
  @media (--viewport-md-min) {
    width: 60px;
    height: 60px;
  }
  &__value {
    font-size: 1.3rem;
    @media (--viewport-ms-min) {
      font-size: 1.5rem;
      line-height: 1.7rem;
    }
    @media (--viewport-md-min) {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  &__unit {
    font-size: 0.525rem;
    line-height: 0.575rem;
    @media (--viewport-ms-min) {
      font-size: 0.625rem;
      line-height: 0.975rem;
    }
    @media (--viewport-md-min) {
      font-size: 0.925rem;
    }
  }
}

.before-after-slider {
  color: white;
}

.no-hero-spacer {
  padding-top: 62px; /* navbar height */
  @media (--navigation-breakpoint-desktop) {
    padding-top: 156px; /* navbar height */
  }
}

.site--sinclair {
  .clinic-finder-landing-page__contact-btn .cta {
    > button,
    > a {
      background: var(--color-white);
    }

    > button:hover,
    > a:hover {
      background: var(--color-dark-blue);
    }
  }

  .clinic-finder-landing-page {
    margin-top: 0;
    @media (--viewport-md-min) {
      margin-top: 0;
    }
  }
}

.lf .locations-map {
  &__card-content {
    & .cta {
      & .button {
        color: var(--color-dark-blue);
        &:hover {
          color: var(--color-white);
        }
      }
    }
  }
}
