/*
 * Base: Sinclair - Themes
 * This is used to apply classes to rows in the grid, which will define a theme for all grid elements in that row.
 * An example of this is to add a blue theme, and then the quote will have a different background.
 */

.theme-sinclair-pastel-satin,
.theme-sinclair-background-01,
.theme-sinclair-background-02,
.theme-sinclair-lighter-blue,
.theme-sinclair-darker-blue {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    max-width: 100vw;
    height: 100.1%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    z-index: -1;
  }
}

.theme-sinclair-pastel-satin {
  &:before {
    background: var(--color-background-02);
  }
}

.theme-sinclair-lighter-blue {
  color: var(--color-white);

  &:before {
    background: var(--color-background-lighter-blue);
  }

  .button {
    border-color: var(--color-white);
    color: var(--color-white);
  }

  .before-after-slider-item__caption {
    color: var(--color-white);
  }

  .rte ul li:before,
  .appetiser ul li:before {
    background-color: var(--color-white);
  }

  .simple-appetiser-content-background {
    mix-blend-mode: soft-light;
  }
}

.theme-sinclair-darker-blue {
  color: var(--color-white);

  &:before {
    background: var(--color-background-darker-blue);
  }

  .button {
    border-color: var(--color-white);
    color: var(--color-white);
  }

  .before-after-slider-item__caption {
    color: var(--color-white);
  }

  .rte ul li:before,
  .appetiser ul li:before {
    background-color: var(--color-white);
  }

  .simple-appetiser-content-background {
    mix-blend-mode: soft-light;
  }
}
