.navigation--service {
  .navigation-list {
    margin: 0;
    max-width: 1113px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @media (--navigation-breakpoint-desktop) {
      min-height: 80px;
    }

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-logo {
      width: 33%;
    }

    &-left {
      justify-content: flex-end;
      width: 33%;
    }

    &-right {
      justify-content: flex-start;
      width: 33%;
    }

    .logo {
      width: 320px;
    }

    &-item {
      /** Define explicit orders so we can slot the logo in the middle - only supports 4 items**/
      margin: 0 0.5rem;

      &__region-selector-toggle {
        padding: 6px 22px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border-radius: 4rem;
          background: var(--color-white);
          opacity: 0.25;
          z-index: -1;
          transition: 0.2s all var(--transition);
        }

        &:hover,
        &:focus {
          &:after {
            opacity: 0.75;
          }
        }

        img {
          display: block;
          margin: 0;
          width: auto;
          height: 26px;
        }
      }

      &:hover {
        .navigation-list-item__link {
          color: #ab92e1;
        }

        .navigation-list-item__icon {
          /** #ab92e1 **/
          filter: brightness(0) saturate(100%) invert(77%) sepia(50%) saturate(3748%)
            hue-rotate(206deg) brightness(96%) contrast(84%);
        }
      }

      &__icon {
        width: 20px;
        margin-right: 8px;
        height: 20px;

        /** See https://novicell.atlassian.net/wiki/spaces/SIP/pages/3458465848/Recolouring+SVGs+with+filter for this technique **/
        filter: brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(80%)
          hue-rotate(152deg) brightness(102%) contrast(101%);
      }

      &__link {
        font-weight: 500;
        letter-spacing: 0.1em;
      }
    }
  }
}

.main-navigation {
  @media (--navigation-breakpoint-mobile) {
    transition: transform 0.4s var(--transition);
  }

  .logo {
    display: none;
  }

  &-list {
    display: flex;
    justify-content: center;

    &--hidden {
      display: none;
    }

    &-item {
      &__link {
        text-align: left;
        font-weight: 500;
        letter-spacing: 0.1em;
      }
    }
  }
}

@media (--viewport-sm-min) {
  .main-navigation-list-item .icon {
    display: none;
  }
}

@media (--navigation-breakpoint-mobile) {
  .header {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.8);
      opacity: 0;
      z-index: 1;
      transition: 0.6s opacity var(--transition);
    }
  }

  .mobile-menu-open {
    .header {
      &:before {
        opacity: 0.5;
      }
    }
  }

  .header {
    top: 0;
    background: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    height: 62px;

    .container {
      padding-left: 20px;
      padding-right: 20px;
    }

    .logo--blue {
      display: none;
    }

    .logo--white {
      display: block;
    }
  }

  .header--shadow:not(.header--sinclair-static) {
    background: var(--color-background-01);
    transition: top 0.2s;

    .logo--blue {
      display: block;
    }

    .logo--white {
      display: none;
    }

    .mobile-menu-toggle {
      &:before,
      &:after {
        background-color: var(--color-midnight-blue);
      }

      &__label:after {
        background-color: var(--color-midnight-blue);
      }
    }
  }

  .mobile-menu-open .header {
    top: 0px;
    transition: top 0.2s;
  }

  .mobile-menu-toggle {
    margin-top: 8px;
  }

  #main-navigation-megamenu {
    display: none;
  }

  .main-navigation {
    padding: 48px 20px 20px;
    background: var(--color-pale-blue);
    transform: translate3d(-100%, 0, 0);
    max-width: 385px;
    overflow-y: hidden;
    overflow-x: hidden;

    &--active {
      transform: translateZ(0);
    }

    .logo {
      &--compact {
        display: none;
      }

      &__image {
        margin: 0 0 0 50px;
      }

      &-white {
        position: absolute;
        top: 32px;
        left: 64px;
        right: 64px;
        display: block;
      }
    }

    &__close {
      position: absolute;
      top: 42px;
      left: var(--container-spacing--mobile);
      right: 16px;
      height: 32px;
      margin: 0;

      &:before,
      &:after {
        height: 2px;
        border-radius: 2px;
      }
    }

    &-page {
      position: absolute;
      top: 98px;
      left: 0;
      width: 100%;
      transition: transform 0.3s var(--transition);
      padding: 0 20px 100px;
      overflow-y: scroll;
      height: calc(100vh - 100px);

      &--hidden {
        pointer-events: none;
      }
    }

    &--current-level-1 {
      .main-navigation-page--1 {
        transform: translateX(0);
      }
      .main-navigation-page--2 {
        transform: translateX(100%);
      }
      .main-navigation-page--3 {
        transform: translateX(100%);
      }
    }
    &--current-level-2 {
      .main-navigation-page--1 {
        transform: translateX(-100%);
      }
      .main-navigation-page--2 {
        transform: translateX(0);
      }
      .main-navigation-page--3 {
        transform: translateX(100%);
      }
    }
    &--current-level-3 {
      .main-navigation-page--1 {
        transform: translateX(-100%);
      }
      .main-navigation-page--2 {
        transform: translateX(-100%);
      }
      .main-navigation-page--3 {
        transform: translateX(0);
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
    }

    &-back {
      position: relative;
      margin-left: 50px;

      &__sep {
        height: 1px;
        margin: 13px 10px 40px;
        border-bottom: 1px solid var(--color-text);
        opacity: 0.5;
      }

      svg {
        fill: var(--color-text);
        transform: rotate(90deg) scale(0.4);
        left: -30px;
        top: -1px;
        position: absolute;
      }
      &__text {
        font-size: 0.875rem; /** 14px **/
        color: var(--color-text);
      }

      &__title {
        margin-top: 5px;
        font-size: 1rem;
        letter-spacing: 1.71px;
        color: var(--color-text);
      }
    }

    &-list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &--hidden {
        display: none;
      }

      &-item {
        &__link {
          color: var(--color-text);
          display: block;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover {
            color: var(--color-soft-violet);
          }
        }
        .icon {
          fill: var(--color-text);
          transform: rotate(-90deg) scale(0.5);
          pointer-events: none;
        }
      }
    }

    &-service-list {
      flex-grow: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;

      @media (--navigation-breakpoint-desktop) {
        display: none;
      }

      @media (--viewport-md-max) {
        flex-direction: column;
      }

      &__seperator {
        flex-basis: 100%;
        height: 2px;
        border-bottom: 1px solid var(--color-text);
        @media (--viewport-md-max) {
          flex-basis: initial;
        }
      }

      &-item {
        --item-padding: 5px;

        padding-top: 23px;
        padding-bottom: 23px;
        display: inline-flex;
        align-items: center;
        color: var(--color-text);

        &__icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;

          /** #012169 **/
          /** See https://novicell.atlassian.net/wiki/spaces/SIP/pages/3458465848/Recolouring+SVGs+with+filter for this technique **/
          filter: brightness(0) saturate(100%) invert(12%) sepia(45%) saturate(4154%)
            hue-rotate(214deg) brightness(93%) contrast(110%);
        }

        &__link {
          display: inline;
        }
      }
    }

    &-region-selector {
      &__toggle {
        display: block;

        img {
          margin-top: 16px;
          width: 32px;
          /** #012169 **/
          /** See https://novicell.atlassian.net/wiki/spaces/SIP/pages/3458465848/Recolouring+SVGs+with+filter for this technique **/
          filter: brightness(0) saturate(100%) invert(12%) sepia(45%) saturate(4154%)
            hue-rotate(214deg) brightness(93%) contrast(110%);
        }
      }
    }
  }
}

@media (--navigation-breakpoint-desktop) {
  .header {
    box-sizing: border-box;
    border-bottom: 1.5px solid white;
    transition: none;
    background: transparent;
    color: var(--color-white);

    &__mobile-content {
      display: none;
    }

    .navigation-list-item__link {
      color: var(--color-white);
      font-size: 0.75rem;
      font-weight: 500;

      &:focus,
      &:hover {
        color: var(--color-pale-blue);
      }
    }

    .main-navigation {
      transform: translate3d(0, 16px, 0);

      &-page {
        &--hidden {
          display: none;
        }
      }

      &-list-item {
        color: var(--color-white);

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          background: var(--color-white);
          width: 100%;
          height: 1px;
          opacity: 0;
          transition: opacity 0.2s var(--transition);
        }

        &--active {
          &::after {
            opacity: 1;
          }
        }
      }

      &-region-selector {
        display: none;
      }
    }

    &--shadow:not(.header--sinclair-static) {
      border-color: var(--color-background-02);

      &-forced {
        border: none;
      }

      background-color: var(--color-background-01);
      color: var(--color-midnight-blue);
      box-shadow: none;

      .main-navigation-list-item {
        color: var(--color-midnight-blue);
      }

      .navigation-list-item__link {
        color: var(--color-midnight-blue);
      }

      .navigation-list-item__region-selector-toggle:after {
        background: var(--color-pale-blue);
        opacity: 1;
      }

      .main-navigation-list-item {
        color: var(--color-midnight-blue);

        &:hover,
        &:focus,
        &--active {
          color: var(--color-pale-blue);
          &::after {
            opacity: 1;
            background: var(--color-pale-blue);
          }
        }
      }
    }

    #main-navigation-megamenu {
      display: none;
    }

    &--megamenu {
      #main-navigation-megamenu {
        transition: 0.2s;
        display: block;
        position: relative;
        top: 30px;
        padding-bottom: 20px;
        padding-top: 20px;
        background-color: var(--color-background-01);
        border-top: 1px solid var(--color-border-01);

        .container {
          display: flex;
          justify-content: flex-start;

          .main-navigation-megamenu {
            &__verticalsep {
              opacity: 0.5;
              border-radius: 25%;
              box-sizing: border-box;
              margin-top: 10px;
              margin-left: 30px;
              margin-bottom: 10px;
              width: 1px;
              background-color: var(--color-border-01);
            }

            &__level2 {
              font-size: 1.125rem; /** 18px **/
              letter-spacing: 1.93px;
              .main-navigation-megamenu__item {
                padding-bottom: 8px;

                a {
                  display: block;
                }

                .icon {
                  pointer-events: none;
                  fill: currentColor;
                  float: right;
                  transform: rotate(-90deg) scale(0.5);
                  position: relative;
                  top: 2px;
                  left: 5px;
                }
              }
            }

            &__level3 {
              padding-top: 0px;
              font-size: 1.125rem; /** 18px **/
              letter-spacing: 0.41px;
              .main-navigation-megamenu__item {
                padding-bottom: 10px;

                a {
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }

            &__group {
              display: flex;
              flex-direction: column;

              &--hidden {
                display: none;
              }
            }

            &__item {
              a {
                padding-top: 8px;
                padding-bottom: 8px;
                color: var(--color-midnight-blue);
                &:hover {
                  color: var(--color-soft-violet);
                }
              }
            }
          }
        }
      }
    }

    &--mini {
      height: 114px;
      border-bottom: 0;

      #main-navigation-megamenu {
        top: 0;
      }

      .main-navigation {
        display: flex;
        align-items: center;
        min-height: 90px;

        .logo {
          display: inline;
          margin-right: 30px;
          position: relative;
          top: -3px;

          &--white {
            display: none;
          }
        }
      }

      .navigation--service {
        display: none;
      }
    }
  }
}

.header {
  ul {
    font-family: var(--heading-font-family);
    list-style-type: none;
  }

  &__mobile-content {
    flex: 1;
    margin-right: 32px;
  }

  &--mini {
    top: 0;
  }
}

.header .navigation-list {
  .logo--white {
    display: block;
  }

  .logo--blue {
    display: none;
  }
}

.header--shadow:not(.header--sinclair-static) .navigation-list {
  .logo--white {
    display: none;
  }

  .logo--blue {
    display: block;
  }

  &-item__icon {
    /** See https://novicell.atlassian.net/wiki/spaces/SIP/pages/3458465848/Recolouring+SVGs+with+filter for this technique **/
    filter: brightness(0) saturate(100%) invert(10%) sepia(48%) saturate(5080%) hue-rotate(208deg)
      brightness(98%) contrast(117%);
  }
}
