/**
 * Base: Typography
 */

h1 {
  display: block;
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  margin-top: 0;
  margin-bottom: 16px;

  font-size: 2rem; /* 32px */
  line-height: 2.4375rem; /* 39px */
  letter-spacing: 0.05rem; /* 0.8px */

  @media (--viewport-md-min) {
    line-height: 3.625rem; /* 58px */
    font-size: 3rem; /* 48px */
    letter-spacing: 0.075rem; /* 2px */
  }
}

h2 {
  display: block;
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.875rem; /* 30px */
  line-height: 2.625rem; /* 42px */
  letter-spacing: 0.75px; /* 0.75px */

  @media (--viewport-md-min) {
    font-size: 2.75rem; /* 44px */
    line-height: 3.375rem; /* 54px */
    letter-spacing: 1px; /* 1px */
  }
}

h3 {
  display: block;
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  margin-top: 0;
  margin-bottom: 16px;

  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  letter-spacing: 0.03625rem; /* 0.58px */

  @media (--viewport-md-min) {
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
    letter-spacing: 0.04375rem; /* 0.7px */
    font-weight: 500;
  }

  p + & {
    margin-top: 4rem;
  }
}

h4 {
  display: block;
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--color-deepblue);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem; /* 24px */
  line-height: 1.875rem; /* 30px */
}

p {
  font-family: var(--base-font-family);
  font-size: 0.875rem; /* 14px */
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 1.1875rem; /* 19px */
  letter-spacing: 0.58px;

  @media (--viewport-md-min) {
    font-size: 1rem; /* 16px */
    line-height: 1.25rem; /* 20px */
    letter-spacing: 0.67px; /* 0.67px */
  }
}

.quirk
{
  color: var(--color-soft-violet)
}

.label {
  text-transform: uppercase;
  font-size: 0.875rem; /* 14px */
  color: black;
  letter-spacing: 0.58px;

  @media (--viewport-md-min) {
    font-size: 1.125rem; /* 18px */
    letter-spacing: 0.75px; /* 0.67px */
  }
}

.pullout {
  font-family: var(--heading-font-family);
  line-height: 1.3;
  color: var(--color-midnight-blue);
  font-weight: 400;
  font-size: 1.5rem; /** 24px **/

  @media (--viewport-md-min) {
    font-size: 2.25rem; /** 36px **/
  }
}

/* Bold */
b,
strong {
  font-weight: 700;
}

/* Small */
small {
  font-size: 0.875rem; /* 14px */
}

a {
  text-decoration: none;
}